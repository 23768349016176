import { type DialogProps, useMediaQuery, useTheme } from "@mui/material";
import { type PropsWithChildren, type ReactNode } from "react";
import { FullScreenDialog } from "./FullScreenDialog";
import { ModalDialog } from "./ModalDialog";

export type Props = PropsWithChildren<
  {
    title: ReactNode;
    onClose?: () => void;
  } & Omit<DialogProps, "onClose" | "title">
>;

export default function Dialog(props: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const DialogType = fullScreen ? FullScreenDialog : ModalDialog;

  return <DialogType {...props} />;
}
