import { Box, Toolbar } from "@mui/material";
import { PropsWithChildren } from "react";
import NavBar from "./NavBar";
import SuspenseBoundary from "./SuspenseBoundary";
import { UserMenuItem } from "./UserMenuItem";

export default function Layout({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) {
  return (
    <>
      <NavBar title={title}>
        <Box sx={{ ml: "auto" }}>
          <UserMenuItem />
        </Box>
      </NavBar>
      <Box sx={{ flex: 1 }}>
        <Toolbar />
        <SuspenseBoundary>{children}</SuspenseBoundary>
      </Box>
    </>
  );
}
