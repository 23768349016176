import {Link, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import type { Environment } from "../../../../api/domains/applications";

export default function EnvironmentInfo({
  environment,
}: {
  environment: Environment;
}) {
    const date = new Date(environment.timestamp);
    const formattedDate = date.toLocaleString();
  return (
      <>
          <Stack direction="column">
              <Typography sx={{ml:2}}>{environment.label}</Typography>
                  <ListItem>
                      <ListItemText sx={{mt:0, pt:0}}
                          primary={<Typography variant="body1">{environment.version}</Typography>}
                                    secondary={<Stack direction="column">
                                        <Link color="black" href={environment.url} target="_blank" rel="noopener noreferrer">{environment.url}</Link>
                                        <Typography variant="body2">{formattedDate}</Typography>
                      </Stack>}
                      />
                  </ListItem>
          </Stack>


      </>

  );
}
