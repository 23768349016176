import { LoadingButton } from "@mui/lab";
import { type ButtonProps } from "@mui/material";
import { ReactNode } from "react";

export type Props = Omit<ButtonProps, "startIcon" | "endIcon"> & {
  icon?: ReactNode;
  loading?: boolean;
};

export default function SubmitButton({
  icon,
  loading,
  disabled,
  ...props
}: Props) {
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      type="submit"
      loading={loading}
      loadingPosition={icon ? "start" : "center"}
      startIcon={icon}
      disabled={disabled}
      {...props}
    />
  );
}
