
import { Form, Formik, FormikHelpers } from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useCallback } from "react";
import * as yup from "yup";
import FormActions from "../../components/dialog/FormActions";
import UserAccountFormContent from "./UserAccountFormContent";
import {useUpdateAuthToken} from "../../benutzer";
import {useAddTech, useTech} from "../../pages/favorites/data";

export type Props = {
  onClose?: () => void;
};

const schema = yup
  .object({
    auftragssystemToken: yup.string(),
    tech: yup.string()
  })
  .required();

export type FormValues = yup.InferType<typeof schema>;


export default function UserAccountForm({ onClose }: Props) {
  const tech = useTech();
  const initialValues: FormValues = {
    auftragssystemToken: "",
    tech:tech.tech ?? ""
  };
  const { mutateAsync } = useUpdateAuthToken("auftragssystem");
  const { mutate } = useAddTech();
  const onSubmit = useCallback(
    async (
      { auftragssystemToken,tech }: FormValues,
      { setSubmitting, setStatus }: FormikHelpers<FormValues>
    ) => {
      setStatus(undefined);
      try {
        if(auftragssystemToken != null){
          if(auftragssystemToken !== ""){
            await mutateAsync({
              token: auftragssystemToken,
            });
          }
        }
        if(tech !== null){
          if(tech !== ""){
            mutate({
              tech: tech ?? "LEER",
            });
          }
        }
        onClose?.();
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutateAsync, onClose, mutate]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <>
        <DialogContent>
          <Form id="konto-bearbeiten" noValidate>
            <UserAccountFormContent />
          </Form>
        </DialogContent>
        <DialogActions>
          <FormActions form="konto-bearbeiten" onClose={onClose} />
        </DialogActions>
      </>
    </Formik>
  );
}
