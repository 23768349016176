
import Dialog from "../../components/dialog";
import UserAccountForm from "./UserAccountForm";

export default function UserAccountDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  return (
    <Dialog title="Konto" open={open} onClose={onClose}>
      <UserAccountForm onClose={onClose} />
    </Dialog>
  );
}
