import { Favorite, FavoriteBorder, Visibility } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import type { DashboardInfo } from "../../api/app";
import Layout from "../../components/Layout";
import LinkBehavior from "../../components/LinkBehavior";
import Main from "../../components/Main";
import DashboardDeleteButton from "./DashboardDeleteButton";
import {
  useDashboardFavorites,
  useDashboards,
  useDeleteDashboardFavorite,
  useSetDashboardFavorite,
} from "./data";
import DashboardAddButton from "./DashboardAddButton";

export default function DashboardList() {
  const dashboard = useDashboards();

  return (
    <Layout title="Alle Dashboards">
      <Main maxWidth="sm">
        <Paper>
          <List>
            {dashboard.items.map((dashboard) => (
              <DashboardListItem key={dashboard.id} dashboard={dashboard} />
            ))}
          </List>
        </Paper>
        <DashboardAddButton></DashboardAddButton>
      </Main>
    </Layout>
  );
}

function DashboardListItem({ dashboard }: { dashboard: DashboardInfo }) {
  const favorites = useDashboardFavorites();
  const favorite = Boolean(favorites.items.find((f) => f.id === dashboard.id));
  const { mutate: setFavorite } = useSetDashboardFavorite(dashboard.id);
  const { mutate: deleteFavorite } = useDeleteDashboardFavorite(dashboard.id);

  return (
    <ListItem>
      <ListItemText primary={dashboard.label} />
      <ListItemSecondaryAction>
        <IconButton
          component={LinkBehavior}
          href={`/dashboards/${dashboard.id}`}
        >
          <Visibility />
        </IconButton>
        {favorite ? (
          <IconButton onClick={() => deleteFavorite()}>
            <Favorite />
          </IconButton>
        ) : (
          <IconButton onClick={() => setFavorite()}>
            <FavoriteBorder />
          </IconButton>
        )}
        <DashboardDeleteButton dashboardId={dashboard.id} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
