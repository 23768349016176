/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDashboard } from '../models/CreateDashboard';
import type { CreateDashboardWidget } from '../models/CreateDashboardWidget';
import type { Dashboard } from '../models/Dashboard';
import type { DashboardPage } from '../models/DashboardPage';
import type { UpdateDashboardWidget } from '../models/UpdateDashboardWidget';
import type { WidgetList } from '../models/WidgetList';
import type { WidgetResult } from '../models/WidgetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DashboardsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns DashboardPage All favorite dashboards of the current user
   * @throws ApiError
   */
  public listDashboardFavorites(): CancelablePromise<DashboardPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dashboard-favorites',
    });
  }

  /**
   * @param dashboardId
   * @returns void
   * @throws ApiError
   */
  public setDashboardFavorite(
    dashboardId: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/dashboard-favorites/{dashboardId}',
      path: {
        'dashboardId': dashboardId,
      },
    });
  }

  /**
   * @param dashboardId
   * @returns void
   * @throws ApiError
   */
  public deleteDashboardFavorite(
    dashboardId: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/dashboard-favorites/{dashboardId}',
      path: {
        'dashboardId': dashboardId,
      },
    });
  }

  /**
   * @returns DashboardPage All available dashboards
   * @throws ApiError
   */
  public listDashboards(): CancelablePromise<DashboardPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dashboards',
    });
  }

  /**
   * @param requestBody
   * @returns Dashboard The created dashboard
   * @throws ApiError
   */
  public createDashboard(
    requestBody: CreateDashboard,
  ): CancelablePromise<Dashboard> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dashboards',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param dashboardId
   * @returns Dashboard The dashboard
   * @throws ApiError
   */
  public getDashboard(
    dashboardId: string,
  ): CancelablePromise<Dashboard> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dashboards/{dashboardId}',
      path: {
        'dashboardId': dashboardId,
      },
    });
  }

  /**
   * @param dashboardId
   * @returns any No content
   * @throws ApiError
   */
  public deleteDashboard(
    dashboardId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/dashboards/{dashboardId}',
      path: {
        'dashboardId': dashboardId,
      },
    });
  }

  /**
   * @param dashboardId
   * @param requestBody
   * @returns WidgetResult The created widget
   * @throws ApiError
   */
  public createDashboardWidget(
    dashboardId: string,
    requestBody: CreateDashboardWidget,
  ): CancelablePromise<WidgetResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dashboards/{dashboardId}/widgets',
      path: {
        'dashboardId': dashboardId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param dashboardId
   * @param requestBody
   * @returns any No content
   * @throws ApiError
   */
  public updateDashboardWidgetOrder(
    dashboardId: string,
    requestBody: WidgetList,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dashboards/{dashboardId}/updatewidgetorder',
      path: {
        'dashboardId': dashboardId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param dashboardId
   * @param widgetId
   * @param requestBody
   * @returns WidgetResult The updated widget result
   * @throws ApiError
   */
  public updateDashboardWidget(
    dashboardId: string,
    widgetId: string,
    requestBody: UpdateDashboardWidget,
  ): CancelablePromise<WidgetResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/dashboards/{dashboardId}/widgets/{widgetId}',
      path: {
        'dashboardId': dashboardId,
        'widgetId': widgetId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param dashboardId
   * @param widgetId
   * @returns any No content
   * @throws ApiError
   */
  public deleteDashboardWidget(
    dashboardId: string,
    widgetId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/dashboards/{dashboardId}/widgets/{widgetId}',
      path: {
        'dashboardId': dashboardId,
        'widgetId': widgetId,
      },
    });
  }

}
