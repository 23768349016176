/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListMuellKalender } from '../models/ListMuellKalender';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TeamprogrammingService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any no content
   * @throws ApiError
   */
  public createMuellkalenderEntry(
    requestBody: ListMuellKalender,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/muellkalender',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param kw
   * @returns ListMuellKalender list Muellkalender Entries
   * @throws ApiError
   */
  public listKwEntries(
    kw: string,
  ): CancelablePromise<ListMuellKalender> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/muellkalender/{kw}',
      path: {
        'kw': kw,
      },
    });
  }

}
