/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelegTile } from '../models/BelegTile';
import type { GetTech } from '../models/GetTech';
import type { SaveTech } from '../models/SaveTech';
import type { Tiles } from '../models/Tiles';
import type { WidgetRequest } from '../models/WidgetRequest';
import type { WidgetResponse } from '../models/WidgetResponse';
import type { Widgets } from '../models/Widgets';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UnitradeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns GetTech get Tech
   * @throws ApiError
   */
  public getTech(): CancelablePromise<GetTech> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tech/',
    });
  }

  /**
   * @param requestBody
   * @returns any save Tech
   * @throws ApiError
   */
  public saveTech(
    requestBody: SaveTech,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/tech/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param tech
   * @returns Tiles get Favoriten
   * @throws ApiError
   */
  public getFavoriten(
    tech: string,
  ): CancelablePromise<Tiles> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/favoriten/{tech}',
      path: {
        'tech': tech,
      },
    });
  }

  /**
   * @param id
   * @returns BelegTile get Favorite
   * @throws ApiError
   */
  public getFavorite(
    id: string,
  ): CancelablePromise<BelegTile> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/favorite/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id
   * @returns WidgetResponse deleted favorite
   * @throws ApiError
   */
  public deleteFavorite(
    id: string,
  ): CancelablePromise<WidgetResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/favorite/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns WidgetResponse update response
   * @throws ApiError
   */
  public updateFavoritenOrder(
    requestBody: WidgetRequest,
  ): CancelablePromise<WidgetResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/favoriten/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param tech
   * @returns Widgets get Favoriten Widgets
   * @throws ApiError
   */
  public getFavoritenWidgets(
    tech: string,
  ): CancelablePromise<Widgets> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/favoriten-widgets/{tech}',
      path: {
        'tech': tech,
      },
    });
  }

}
