import {
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  Favorite as FavoritesIcon,
  List as ListIcon,
    Settings as SettingsIcon,
    Star as StarIcon
} from "@mui/icons-material";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { version } from "../config";
import { useDashboardFavorites } from "../pages/dashboard/data";
import LinkBehavior from "./LinkBehavior";
import UserAccountButton from "./useraccount/UserAccountButton";

export default function MainMenu({ open }: { open: boolean }) {
  return (
    <Drawer variant="persistent" open={open}>
      <Toolbar />
      <List>
        <MeineDashboards />
        <ListItemButton component={LinkBehavior} href="/dashboard-list" nav>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Alle Dashboards" />
        </ListItemButton>
        <ListItemButton component={LinkBehavior} href="/application-list" nav>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Anwendungen" />
        </ListItemButton>
        <ListItemButton component={LinkBehavior} href="/favorites" nav>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Favoriten" />
        </ListItemButton>
        <ListItemButton component={LinkBehavior} href="/settings" nav>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Einstellungen" />
        </ListItemButton>
        <UserAccountButton></UserAccountButton>
      </List>
      <List sx={{ mt: "auto" }} dense>
        <Divider />
        <ListItem>
          <ListItemText primary="Dashboard" secondary={version} />
        </ListItem>
      </List>
    </Drawer>
  );
}

function MeineDashboards() {
  const { items: favorites } = useDashboardFavorites();
  const [expanded, setExpanded] = useState(false);
  if (!favorites.length) return null;

  return (
    <>
      <ListItemButton onClick={() => setExpanded((e) => !e)}>
        <ListItemIcon>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary="Meine Dashboards" />
      </ListItemButton>
      <Collapse in={expanded} unmountOnExit>
        <List component="div" disablePadding>
          {favorites.map((favorite) => (
            <ListItemButton
              component={LinkBehavior}
              href={`/dashboards/${favorite.id}`}
              nav
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <FavoritesIcon />
              </ListItemIcon>
              <ListItemText primary={favorite.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}
