import { Container, type ContainerProps } from "@mui/material";
import { contentWidth } from "../config";

export default function Main(props: ContainerProps) {
  return (
    <Container
      component="main"
      maxWidth={contentWidth}
      {...props}
      sx={{ py: 2 }}
    />
  );
}
