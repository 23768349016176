import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useDeleteApplication} from "../../domains/applications/data";

export default function ApplicationDeleteButton(props: { applicationId: string, applicationLabel: string }) {
  const { mutate } = useDeleteApplication(props.applicationLabel.replace("/",":"), props.applicationId);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon></DeleteIcon>
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Anwendungseintrag wirklich löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sie sind dabei diesen Anwendungseintrag zu löschen. Sind Sie sich sicher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>nicht löschen</Button>
          <Button
            onClick={() => {
              mutate();
              handleClose();
            }}
          >
            löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
