import { Alert, Box, Stack } from "@mui/material";
import Layout from "../../components/Layout";
import Main from "../../components/Main";
import {useFavoriten, useTech} from "./data";
import {DraggableFavorites} from "../drag/DraggableFavorites";
import TechAddButton from "../../components/unitrade/TechAddButton";

export default function Favoriten() {
  const tech = useTech();
  const favorites = useFavoriten(tech.tech ?? "LEER");

  return (
    <Layout title="Favoriten">
      <Main>
        <Stack spacing={2}>
            {tech.tech === "LEER" ? <TechAddButton></TechAddButton> : <></>}
            {favorites.length ? (
                <Box>
                    <DraggableFavorites />
                </Box>
            ) : (
                <Alert severity="info">No widgets yet.</Alert>
            )}

        </Stack>
      </Main>
    </Layout>
  );
}

