import { useEffect } from "react";
import { type To, useNavigate } from "react-router";

export default function Redirect({ to }: { to: To }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace: true });
  }, [navigate, to]);
  return null;
}
