
import {useGetApi} from "../../data";
import {useQuery} from "react-query";
import {OpenAPI, QuoteDomain, QuoteEntry} from "../../api/domains/quote";

function useGetQuoteAPI() {
    return useGetApi<QuoteDomain>(QuoteDomain, OpenAPI);
}
export function useRandomQuote(): QuoteEntry {
    const getAPI = useGetQuoteAPI();
    const rndInt = randomIntFromInterval(1, 9)
    const quote = useQuery(
        ["quote"],
        async () => (await getAPI()).quote.getRandomQuote(rndInt)
    ).data;

    if (!quote) throw new Error("Quote not found");
    return quote;
}
export function useLoadQuote(widgetId: string): QuoteEntry {
    const getAPI = useGetQuoteAPI();
    const quote = useQuery(
        ["quote",widgetId],
        async () => (await getAPI()).quote.loadQuoteFromDb(widgetId),
        {
            refetchInterval: 60000,
        }
    ).data;

    if (!quote) throw new Error("Quote not found");
    return quote;
}
function randomIntFromInterval(min:number, max:number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}