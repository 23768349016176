import {useGetApi} from "../../data";
import {BelegTile, OpenAPI, UnitradeDomain} from "../../api/domains/unitrade";
import {useQuery} from "react-query";

function useGetUnitradeAPI() {
    return useGetApi<UnitradeDomain>(UnitradeDomain, OpenAPI);
}
export function useFavorite(id: string): BelegTile {
    const getAPI = useGetUnitradeAPI();
    const tile = useQuery(
        ["tile", id],
        async () => (await getAPI()).unitrade.getFavorite(id)
    ).data;
    if (!tile) throw new Error("Tile not found");
    return tile;
}