import { Person as PersonIcon} from "@mui/icons-material";
import { ListItemButton, ListItemIcon} from "@mui/material";
import {
    bindPopover,
    bindTrigger,
    usePopupState,
} from "material-ui-popup-state/hooks";
import UserAccountDialog from "./UserAccountDialog";

export default function UserAccountButton() {
    const popupState = usePopupState({
        variant: "dialog",
        popupId: `konto-bearbeiten`,
    });

    return (
        <>
            <ListItemButton
                {...bindTrigger(popupState)}
            >
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                Konto
            </ListItemButton>
            <UserAccountDialog {...bindPopover(popupState)} />
        </>
    );
}