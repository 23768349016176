/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Application } from '../models/Application';
import type { ApplicationPage } from '../models/ApplicationPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApplicationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param seite
   * @param max
   * @returns ApplicationPage All registered applications
   * @throws ApiError
   */
  public listApplications(
    seite?: number,
    max: number = 100,
  ): CancelablePromise<ApplicationPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/applications',
      query: {
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param applicationLabel
   * @returns Application The application
   * @throws ApiError
   */
  public getApplication(
    applicationLabel: string,
  ): CancelablePromise<Application> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/applications/{applicationLabel}',
      path: {
        'applicationLabel': applicationLabel,
      },
    });
  }

  /**
   * @param applicationLabel
   * @param applicationId
   * @returns any No content
   * @throws ApiError
   */
  public deleteApplication(
    applicationLabel: string,
    applicationId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/applications/{applicationLabel}/{applicationId}',
      path: {
        'applicationLabel': applicationLabel,
        'applicationId': applicationId,
      },
    });
  }

}
