import {useGetApi} from "../../data";
import {useQuery} from "react-query";
import {ListMuellKalender, OpenAPI, TeamProgrammingDomain} from "../../api/domains/teamprogramming";

function useGetTeamProgrammingAPI() {
    return useGetApi<TeamProgrammingDomain>(TeamProgrammingDomain, OpenAPI);
}

export function useGetKw(kw: string): ListMuellKalender {
    const getAPI = useGetTeamProgrammingAPI();
    const calender = useQuery(
        ["calender",kw],
        async () => (await getAPI()).teamprogramming.listKwEntries(kw),
        {
            refetchInterval: 60000,
        }
    ).data;

    if (!calender) throw new Error("calender not found");
    return calender;
}