import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {useDeleteFavorite} from "../../pages/favorites/data";

export default function FavoriteDeleteButton({ widgetId }: { widgetId: string }) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `widget-${widgetId}-delete`,
  });
  const { mutate } = useDeleteFavorite(widgetId);

  return (
    <>
      <IconButton sx={{ marginLeft: "auto", position:"absolute", top:0,right:0}} {...bindTrigger(popupState)}>
        <CloseIcon />
      </IconButton>
      <Dialog {...bindPopover(popupState)}>
        <DialogTitle id="alert-dialog-title">
          Widget wirklich löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sie sind dabei dieses Widget zu löschen. Sind Sie sich sicher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => popupState.close()} color="inherit">
            Abbrechen
          </Button>
          <Button variant="contained" color="error" onClick={() => {
            mutate()
            popupState.close()
          }}>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
