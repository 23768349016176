import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import TechAddDialog from "./TechAddDialog";

export default function TechAddButton() {
  const popupState = usePopupState({
    variant: "dialog",
    popupId: `tech-erstellen`,
  });

  return (
    <>
      <Button
          sx={{mt:3}}
        variant="contained"
        startIcon={<Add />}
        {...bindTrigger(popupState)}
      >
        Benutzer hinterlegen
      </Button>
      <TechAddDialog {...bindPopover(popupState)} />
    </>
  );
}
