import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import {App, CreateDashboard, CreateDashboardWidget, WidgetList} from "../../api/app";
import { useGetApi } from "../../data";

export function useDashboards() {
  const getApi = useGetApi<App>(App);
  return useQuery("dashboards", async () =>
    (await getApi()).dashboards.listDashboards()
  ).data!;
}

export function useDashboardFavorites() {
  const getApi = useGetApi<App>(App);
  return useQuery("dashboard-favorites", async () =>
    (await getApi()).dashboards.listDashboardFavorites()
  ).data!;
}

export function useDashboard(id: string) {
  const getApi = useGetApi<App>(App);
  return useQuery(["dashboards", id], async () =>
    (await getApi()).dashboards.getDashboard(id)
  ).data!;
}

export function useAddDashboard() {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
    async (data: CreateDashboard) =>
      (await getApi()).dashboards.createDashboard(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboards"]);
        await queryClient.invalidateQueries(["dashboard-favorites"]);
      },
    }
  );
}

export function useAddWidget(dashboardId: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
    async (data: CreateDashboardWidget) =>
      (await getApi()).dashboards.createDashboardWidget(dashboardId, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboards", dashboardId]);
      },
    }
  );
}

export function useUpdateWidgetOrder(dashboardId: string) {
    const getApi = useGetApi<App>(App);
    const queryClient = useQueryClient();
    return useMutation(
        async (data: WidgetList) =>
            (await getApi()).dashboards.updateDashboardWidgetOrder(dashboardId, data),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["dashboards", dashboardId]);
            },
        }
    );
}
export function useDeleteWidget(widgetId: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  const { dashboardId = "" } = useParams<{ dashboardId: string }>();
  return useMutation(
    async () =>
      (await getApi()).dashboards.deleteDashboardWidget(dashboardId, widgetId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboards", dashboardId]);
      },
    }
  );
}

export function useDeleteDashboard(dashboardId: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
    async () => (await getApi()).dashboards.deleteDashboard(dashboardId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboards"]);
        await queryClient.invalidateQueries(["dashboard-favorites"]);
      },
    }
  );
}

export function useSetDashboardFavorite(dashboardId: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
    async () => (await getApi()).dashboards.setDashboardFavorite(dashboardId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboard-favorites"]);
      },
    }
  );
}

export function useDeleteDashboardFavorite(dashboardId: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      (await getApi()).dashboards.deleteDashboardFavorite(dashboardId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["dashboard-favorites"]);
      },
    }
  );
}
