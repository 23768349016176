import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { Add } from "@mui/icons-material";
import * as React from "react";
import {Button} from "@mui/material";
import AddStationDialog from "./AddStationDialog";

export type Props = {
  dashboardId: string;
};
export default function AddStationButton() {
  const popupState = usePopupState({
    variant: "dialog",
    popupId: `widget-erstellen`,
  });

  return (
    <>
      <Button variant="contained" sx={{ml:2}} {...bindTrigger(popupState)} ><Add/> Bahnhof</Button>
      <AddStationDialog {...bindPopover(popupState)} />
    </>
  );
}
