import {WidgetComponent, WidgetComponentProps} from "../../../types";
import { LinearProgress, Paper, rgbToHex, Stack, Table, TableBody} from "@mui/material";
import Title from "../../../../components/Title";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useGetKw} from "../../data";
import {addDays, getWeek, startOfWeek, format } from 'date-fns';
export const CurrentMuellkalenderWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{pl:2,pt:2,pr:1}}>
            <Title>Kalender</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);
function Content({ widget }: WidgetComponentProps) {
    const currentDate = new Date();
    const weekOfYear = getWeek(currentDate)
    const startOfCurrentWeek = startOfWeek(currentDate);

    // Get an array of dates for the current week (Monday to Sunday)
    const currentWeekDates = Array.from({ length: 7 }, (_, index) =>
        addDays(startOfCurrentWeek, index)
    );
    const stock = useGetKw("KW " + weekOfYear)
    return (
        <Stack direction="column">
            <Table size="small" sx={{pl:5}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Montag</TableCell>
                        <TableCell>Dienstag</TableCell>
                        <TableCell>Mittwoch</TableCell>
                        <TableCell>Donnerstag</TableCell>
                        <TableCell>Freitag</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>KW {weekOfYear}</TableCell>
                        <TableCell>{format(currentWeekDates[0], 'dd.MM')}</TableCell>
                        <TableCell>{format(currentWeekDates[1], 'dd.MM')}</TableCell>
                        <TableCell>{format(currentWeekDates[2], 'dd.MM')}</TableCell>
                        <TableCell>{format(currentWeekDates[3], 'dd.MM')}</TableCell>
                        <TableCell>{format(currentWeekDates[4], 'dd.MM')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {stock.map((calender, index) =>
                            <TableRow key={index}>
                                <TableCell>{calender.name}</TableCell>
                                <TableCell sx={{bgcolor: rgbToHex(calender.montag?.color ? "rgb(" + calender.montag?.color + ")" : "#FFFFFF"), color:rgbToHex(calender.montag?.fontColor ? "rgb(" + calender.montag?.fontColor + ")" : "black")}}>{calender.montag?.value}</TableCell>
                                <TableCell sx={{bgcolor: rgbToHex(calender.dienstag?.color ? "rgb(" + calender.dienstag?.color + ")" : "#FFFFFF"), color:rgbToHex(calender.dienstag?.fontColor ? "rgb(" + calender.dienstag?.fontColor + ")" : "black")}}>{calender.dienstag?.value}</TableCell>
                                <TableCell sx={{bgcolor: rgbToHex(calender.mittwoch?.color ? "rgb(" + calender.mittwoch?.color + ")" : "#FFFFFF"), color:rgbToHex(calender.mittwoch?.fontColor ? "rgb(" + calender.mittwoch?.fontColor + ")" : "black")}}>{calender.mittwoch?.value}</TableCell>
                                <TableCell sx={{bgcolor: rgbToHex(calender.donnerstag?.color ? "rgb(" + calender.donnerstag?.color + ")" : "#FFFFFF"), color:rgbToHex(calender.donnerstag?.fontColor ? "rgb(" + calender.donnerstag?.fontColor + ")" : "black")}}>{calender.donnerstag?.value}</TableCell>
                                <TableCell sx={{bgcolor: rgbToHex(calender.freitag?.color ? "rgb(" + calender.freitag?.color + ")" : "#FFFFFF"), color:rgbToHex(calender.freitag?.fontColor ? "rgb(" + calender.freitag?.fontColor + ")" : "black")}}>{calender.freitag?.value}</TableCell>
                            </TableRow>
                        )}
                    </>


                </TableBody>
            </Table>
        </Stack>
    );
}