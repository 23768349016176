/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { BenutzerService } from './services/BenutzerService';
import { DashboardsService } from './services/DashboardsService';
import { DomainsService } from './services/DomainsService';
import { SystemService } from './services/SystemService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class App {

  public readonly benutzer: BenutzerService;
  public readonly dashboards: DashboardsService;
  public readonly domains: DomainsService;
  public readonly system: SystemService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.benutzer = new BenutzerService(this.request);
    this.dashboards = new DashboardsService(this.request);
    this.domains = new DomainsService(this.request);
    this.system = new SystemService(this.request);
  }
}

