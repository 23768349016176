import { LinearProgress, Link, Paper, Stack, Table, TableBody } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import GlobalLoading from "../../../../components/GlobalLoading";
import {useListDeployments} from "../../data";
import {Deployment} from "../../../../api/domains/applications";

export const LastDeploymentsWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
            <Title >Letzte Deployments</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);

function Content({ widget }: WidgetComponentProps) {
    const deploymentPage = useListDeployments(0,6)
    const [loading] = useState(false)

    return (
        <>
            <Table size="small" sx={{ pl: 5 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Application</TableCell>
                        <TableCell>Environment</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Stand</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <GlobalLoading /> : <>{deploymentPage?.items?.map((deployment) =>
                        <TableRow key={deployment.id}>
                            <TableCell><Link href={deployment.url} color="black" target="_blank" rel="noopener noreferrer">{deployment.application}</Link></TableCell>
                            <TableCell><Link href={deployment.url} color="black" target="_blank" rel="noopener noreferrer">{deployment.environment}</Link></TableCell>
                            <TableCell>
                                {deployment.version}
                            </TableCell>
                            <Stand deployment={deployment}></Stand>
                        </TableRow>
                    )}</>}

                </TableBody>
            </Table>
        </>
    );
}
interface Props{
    deployment: Deployment
}
function Stand({ deployment }: Props) {
    const date = new Date(deployment.timestamp);
    const formattedDate = date.toLocaleString();
    return <TableCell>
        {formattedDate}
    </TableCell>
}