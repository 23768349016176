import {AuftragssystemDomain, FridgeEntry, PresenceEntry} from "../../api/domains/auftragssystem";
import {useGetApi} from "../../data";
import {OpenAPI} from "../../api/domains/auftragssystem";
import { useQuery} from "react-query";

function useGetAuftragssystemAPI() {
    return useGetApi<AuftragssystemDomain>(AuftragssystemDomain, OpenAPI);
}
export function useGetPresences(): PresenceEntry {
    const getAPI = useGetAuftragssystemAPI();
    const presence = useQuery(
        ["presences"],
        async () => (await getAPI()).auftragssystem.getPresences(),
    {
        refetchInterval: 90000,
    }
    ).data;

    if (!presence) throw new Error("Presence not found");
    return presence;
}
export function useSavePresences(email: string,widgetId:string): PresenceEntry {
    const getAPI = useGetAuftragssystemAPI();
    const presence = useQuery(
        ["presences2",email],
        async () => (await getAPI()).auftragssystem.savePresences(email,widgetId),
        {
            refetchInterval: 6000,
        }
    ).data;


    if (!presence) throw new Error("Presence not found");
    return presence;
}
export function useLoadPresences(widgetId:string): PresenceEntry {
    const getAPI = useGetAuftragssystemAPI();
    const presence = useQuery(
        ["presence",widgetId],
        async () => (await getAPI()).auftragssystem.loadPresences(widgetId),
        {
            refetchInterval: 6000,
        }
    ).data;

    if (!presence) throw new Error("Presence not found");
    return presence;
}
export function useGetFridgeCount(ort: string): FridgeEntry {
    const getAPI = useGetAuftragssystemAPI();

    const fridge = useQuery(
        ["fridges",ort],
        async () => (await getAPI()).auftragssystem.loadFridgeCount(ort)
    ).data;

    if (!fridge) throw new Error("Fridge not found");
    return fridge;
}

