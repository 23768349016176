import { customAlphabet } from "nanoid";
import { useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { apiUrl, version } from "./config";

const randomString = customAlphabet(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  13
);

export function useGetApi<T>(
  constructor: any,
  config?: { BASE: string }
): () => Promise<T> {
  const getApi = useUnauthenticatedAPI<T>(constructor, config);
  const getAccessToken = useGetAccessToken();

  return useCallback(async () => {
    const accessToken = await getAccessToken();
    return getApi(accessToken);
  }, [getAccessToken, getApi]);
}

export function useUnauthenticatedAPI<T>(
  constructor: any,
  config: { BASE: string } = { BASE: "" }
): (accessToken?: string) => Promise<T> {
  return useCallback(
    async (accessToken) => {
      const correlationId = Date.now().toString() + "." + randomString();

      return new constructor({
        BASE: apiUrl + config.BASE,
        TOKEN: accessToken,
        HEADERS: {
          "x-correlation-id": correlationId,
          "x-ui-version": version,
        },
      }) as T;
    },
    [constructor, config]
  );
}

function useGetAccessToken(): () => Promise<string | undefined> {
  const accessToken = useAuth().user?.access_token;

  return useCallback(async () => accessToken, [accessToken]);
}
