import {useGetApi} from "../../data";
import {BahnDomain, CreateStation, OpenAPI, Stations, TimetableEntry} from "../../api/domains/bahn";
import {useMutation, useQuery, useQueryClient} from "react-query";

function useGetBahnAPI() {
    return useGetApi<BahnDomain>(BahnDomain, OpenAPI);
}

export function useListTimeTable(evaId: string): TimetableEntry | undefined {
    const getAPI = useGetBahnAPI();

    return useQuery(
        ["timetable"],
        async () => (await getAPI()).bahn.getTimetable(evaId),
        {
            refetchInterval: 300000,
        }
    ).data;

}
export function useListStations(): Stations | undefined {
    const getAPI = useGetBahnAPI();

    return useQuery(
        ["stations"],
        async () => (await getAPI()).bahn.listStations(),
        {
            refetchInterval: 300000,
        }
    ).data;

}
export function useAddStation() {
    const getApi = useGetBahnAPI();
    const queryClient = useQueryClient();
    return useMutation(
        async (data: CreateStation) =>
            (await getApi()).bahn.addStation(data),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["stations"]);
            },
        }
    );
}

export function useListAllStations(): Stations | undefined {
    const getAPI = useGetBahnAPI();

    return useQuery(
        ["stations-all"],
        async () => (await getAPI()).bahn.listAllStations(),
        {
            refetchInterval: 300000,
        }
    ).data;

}
export function useDeleteStation(evaId: string) {
    const getApi = useGetBahnAPI();
    const queryClient = useQueryClient();
    return useMutation(
        async () => (await getApi()).bahn.deleteStation(evaId),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["stations"]);
            },
        }
    );
}