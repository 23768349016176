import { Field, useFormikContext } from "formik";
import { Alert, Stack } from "@mui/material";
import { TextField } from "formik-mui";
import * as React from "react";
import {FormValues} from "./DashboardAddForm";

export default function DashboardAddFormContent() {
  const { status } = useFormikContext<FormValues>();
  return (
    <Stack spacing={2} sx={{ pt: 1 }}>
      <Field component={TextField} name="label" label="Name" required />
      {status && <Alert severity="error">{status}</Alert>}
    </Stack>
  );
}
