import {
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useDeleteStation} from "../../domains/bahn/data";

export default function StationDeleteButton(props: { evaId: string}) {
    const { mutate } = useDeleteStation(props.evaId);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <DeleteIcon></DeleteIcon>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Bahnhof wirklich löschen?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sie sind dabei diesen Bahnhof zu löschen. Sind Sie sich sicher?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>abbrechen</Button>
                    <Button variant="contained" color="error"
                        onClick={() => {
                            mutate();
                            handleClose();
                        }}
                    >
                        löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}