/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomainPage } from '../models/DomainPage';
import type { WidgetTypePage } from '../models/WidgetTypePage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DomainsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns DomainPage All available domains
   * @throws ApiError
   */
  public listDomains(): CancelablePromise<DomainPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/domains',
    });
  }

  /**
   * @param domainId
   * @returns WidgetTypePage All available widget types of a domain
   * @throws ApiError
   */
  public listDomainWidgetTypes(
    domainId: string,
  ): CancelablePromise<WidgetTypePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/domains/{domainId}/widget-types',
      path: {
        'domainId': domainId,
      },
    });
  }

}
