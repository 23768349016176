import { Alert } from "@mui/material";
import { widgets as applicationsWidgets } from "./applications";
import { widgets as zabbixWidgets } from "./zabbix";
import { widgets as weatherWidgets } from "./weather";
import { widgets as comicWidgets } from "./xkcd";
import { widgets as zitateWidgets } from "./quotes";
import { widgets as auftragssystemWidgets } from "./auftragssystem";
import { widgets as bahnWidgets } from "./bahn";
import { widgets as unitradeWidgets } from "./unitrade";
import { widgets as teamprogrammingWidgets } from "./teamprogramming";
import type { WidgetComponent, WidgetComponentProps } from "./types";

const widgets: Record<string, WidgetComponent> = {
  ...applicationsWidgets,
  ...zabbixWidgets,
  ...weatherWidgets,
  ...comicWidgets,
  ...zitateWidgets,
  ...auftragssystemWidgets,
  ...bahnWidgets,
  ...teamprogrammingWidgets,
  ...unitradeWidgets,
};

export default function WidgetDelegate(props: WidgetComponentProps) {
  const Comp = widgets[props.widget.type];

  return Comp ? (
    <Comp {...props} />
  ) : (
    <Alert severity="error">Unknown widget type: {props.widget.type}</Alert>
  );
}
