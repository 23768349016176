import { useNavigate } from "react-router";
import { Form, Formik, FormikHelpers } from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useCallback } from "react";
import * as yup from "yup";
import FormActions from "../../components/dialog/FormActions";
import TechAddFormContent from "./TechAddFormContent";
import {useAddTech} from "../../pages/favorites/data";

export type Props = {
  onClose?: () => void;
};

const schema = yup
  .object({
    tech: yup.string().required().min(1).max(255),
  })
  .required();

export type FormValues = yup.InferType<typeof schema>;

const initialValues: FormValues = {
  tech: "",
};
export default function DashboardAddForm({ onClose }: Props) {
  const { mutateAsync } = useAddTech();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      { tech }: FormValues,
      { setSubmitting, setStatus }: FormikHelpers<FormValues>
    ) => {
      setStatus(undefined);
      try {
        await mutateAsync({
          tech: tech.toUpperCase(),
        });
        navigate(`/favorites`);
        onClose?.();
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutateAsync, navigate, onClose]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <>
        <DialogContent>
          <Form id="benutzer-hinterlegen" noValidate>
            <TechAddFormContent />
          </Form>
        </DialogContent>
        <DialogActions>
          <FormActions form="benutzer-hinterlegen" onClose={onClose} />
        </DialogActions>
      </>
    </Formik>
  );
}
