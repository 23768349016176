import React, {useEffect, useState} from 'react';
import {DndContext, DragEndEvent, DragStartEvent} from '@dnd-kit/core';
import {Droppable} from './Droppable';
import { Grid } from '@mui/material';
import WidgetDelegate from "../../domains/WidgetDelegate";
import {Widget} from "../../api/app";
import {useFavoriten, useTech, useUpdateFavoriteOrder} from "../favorites/data";
import {DraggableFavorite} from "../favorites/DraggableFavorite";
import {WidgetRequest } from '../../api/domains/unitrade';

export function DraggableFavorites() {
    const tech = useTech();
    const favorites = useFavoriten(tech.tech);
    const [widgets, setWidgets] = useState<Widget[]>(favorites);
    const [dragItemIndex, setDragItemIndex] = useState<number | null>(null);
    const mutateWidgetOrder = useUpdateFavoriteOrder();
    useEffect(() => {
        setWidgets(favorites)
    }, [favorites]);
    return (
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <Grid container spacing={2}>
                {widgets.map((widget, index) =>(
                    <Grid
                        key={widget.id}
                        item
                        xs={12}
                        md={getMediumWidgetSize(widget)}
                        lg={getWidgetSize(widget)}
                    >
                        <DraggableFavorite id={index.toString()} widgetId={widget.id}>
                            <Droppable id={index}>
                               <WidgetDelegate widget={widget}></WidgetDelegate>
                            </Droppable>
                        </DraggableFavorite>
                    </Grid>
                ))}

            </Grid>

        </DndContext>
    );

    function handleDragEnd({ over }: DragEndEvent) {
        if (dragItemIndex !== null && over) {
            const draggedItem = widgets[dragItemIndex];
            const newItems = [...widgets];
            newItems.splice(dragItemIndex, 1);
            if (typeof over.id === "number") {
                newItems.splice(over.id, 0, draggedItem);
            }
            setWidgets(newItems);
            setDragItemIndex(null);
            let request: WidgetRequest = { widgets: newItems };
            mutateWidgetOrder.mutate(request)
        }
    }
    function handleDragStart(event:DragStartEvent) {
        setDragItemIndex(Number(event.active.id));
    }
    function getWidgetSize(widget: Widget): number {
        switch (widget.parameters.size) {
            case "extra-large":
                return 7;
            case "large":
                return 6;
            case "extra-small":
                return 2;
            default:
                return 3;
        }
    }
    function getMediumWidgetSize(widget: Widget): number {
        switch (widget.parameters.size) {
            case "extra-large":
                return 7;
            case "large":
                return 6;
            case "extra-small":
                return 4;
            default:
                return 4;
        }
    }
}