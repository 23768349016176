import { lazy, useMemo } from "react";
import { type RouteObject } from "react-router";
import ApplicationItem from "../application/ApplicationItem";
import ApplicationList from "../application/ApplicationList";
import Settings from "../settings/Settings";
import DashboardList from "./DashboardList";
import Favoriten from "../favorites/Favoriten";

const Dashboard = lazy(() => import("./Dashboard"));
const DashboardIndex = lazy(() => import("./DashboardIndex"));

export function useDashboardRoutes(): Array<RouteObject> {
  return useMemo(
    () => [
      {
        path: "/dashboards",
        children: [
          { index: true, element: <DashboardIndex /> },
          { path: ":dashboardId", element: <Dashboard /> },
        ],
      },
      {
        path: "/dashboard-list",
        element: <DashboardList />,
      },
      {
        path: "/application-List",
        element: <ApplicationList />,
      },
      {
          path: "/settings",
          element: <Settings />,
      },
        {
            path: "/favorites",
            element: <Favoriten />,
        },
      {
        path: "/application/:application",
        element: <ApplicationItem />,
      },
    ],
    []
  );
}
