import {WidgetComponent, WidgetComponentProps} from "../../../types";
import {LinearProgress, Paper, Stack, Typography} from "@mui/material";
import Title from "../../../../components/Title";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import * as React from "react";
import {useLoadQuote} from "../../data";

export const CurrentQuoteWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{pl:2,pt:2,pr:1}}>
            <Title>Zitat</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);
function Content({ widget }: WidgetComponentProps) {
    const quote = useLoadQuote(widget.id)
    return (
        <Stack direction="column">
            <Stack direction="row">
                {quote ? <Typography variant="subtitle1" sx={{textAlign:"center", flexGrow: 1,pl:1,pr:1}}>{quote?.title}</Typography>: <></>}

            </Stack>

            <Typography sx={{textAlign:"center", flexGrow: 1, pb:3}}>{quote?.author}</Typography>
        </Stack>
    );
}