import { Alert, Button } from "@mui/material";

export default function FallbackAlert({
  error,
  resetError,
}: {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
}) {
  return (
    <Alert
      severity="error"
      action={
        <Button color="inherit" onClick={resetError}>
          zurücksetzen
        </Button>
      }
    >
      {error.message}
    </Alert>
  );
}
