import {
  RouterProvider,
  createBrowserRouter,
  type RouteObject,
} from "react-router-dom";
import Redirect from "../components/Redirect";
import { useDashboardRoutes } from "./dashboard";

export default function Pages() {
  return <RouterProvider router={useRouter()} />;
}

function useRouter() {
  const routes: Array<RouteObject> = [
    {
      path: "/",
      children: [
        { index: true, element: <Redirect to="/dashboards" /> },
        ...useDashboardRoutes(),
      ],
    },
  ];

  return createBrowserRouter(routes);
}
