import {useGetApi} from "../../data";
import {ZabbixDomain, OpenAPI, ProblemsPage} from "../../api/domains/zabbix";
import {useQuery} from "react-query";

function useGetZabbixAPI() {
    return useGetApi<ZabbixDomain>(ZabbixDomain, OpenAPI);
}

export function useListProblems(): ProblemsPage | undefined {
    const getAPI = useGetZabbixAPI();

    const problemsPage = useQuery(
        ["problems"],
        async () => (await getAPI()).zabbix.listProblems()
    ).data;
    return problemsPage;

}


