import { Autocomplete, TextField as MuiTextfield } from "@mui/material";
import { TextField } from "formik-mui";
import * as React from "react";
import {StationEntry, Stations} from "../../api/domains/bahn";
import {Field, useFormikContext} from "formik";
import {useEffect} from "react";

export type Props = {
    stations: Stations
};
export default function AddStationFormContent({ stations }: Props) {
    const [selectedStation, setSelectedStation] = React.useState<StationEntry | null>(null);
    const handleAutocompleteChange = (event: React.SyntheticEvent, value: StationEntry | null) => {
        setSelectedStation(value);

    };
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (selectedStation) {
            setFieldValue("eva", selectedStation.eva || "").then();
        }
    }, [selectedStation, setFieldValue]);
  return (
      <>
          <Autocomplete
              disablePortal
              id="eva"
              options={stations}
              getOptionLabel={option => option.label}
              onChange={handleAutocompleteChange}
              value={selectedStation}
              sx={{ mt:2 }}
              fullWidth={true}
              renderInput={(params) => <MuiTextfield {...params}  label="Bahnhof"   InputProps={{
                  ...params.InputProps,
                  type: 'search',
              }}/>}
          />
          <Field sx={{mt:2}} component={TextField} name="eva" label="Eva" ></Field>
      </>

  );
}
