import {Box, Chip, LinearProgress, Paper, Stack, Table, TableBody, Tooltip} from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import {useListTimeTable} from "../../data";
import { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import GlobalLoading from "../../../../components/GlobalLoading";

export const CurrentTimetableWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
            <Title>{props.widget.parameters.Ort}</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);

function Content({ widget }: WidgetComponentProps) {
    const timeTable = useListTimeTable(widget.parameters.application)
    const [loading] = useState(false)

    return (
        <>
            <Table size="small" sx={{ pl: 5 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Zug</TableCell>
                        <TableCell>Nach</TableCell>
                        <TableCell>Abfahrtszeit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <GlobalLoading /> : <>{timeTable?.trains?.map((train) =>
                        <TableRow key={train.id}>
                            <TableCell><Chip label={train.trainTypeAndNumber}></Chip></TableCell>
                            <TableCell>
                                <Tooltip title={train.departure?.route ? train.departure?.route : train.arrival?.route} placement="bottom-start">
                                {train.hasMessage ?
                                    <Stack>
                                        <Box>{train.to}</Box>
                                        <Box sx={{color: 'red'}}>{train.message}</Box>
                                    </Stack> :
                                    <Box>{train.to}</Box>
                                }
                                </Tooltip>
                            </TableCell>
                            {train.departure ? <TableCell>
                                {train.hasTimeChange ?
                                <Stack direction="column">
                                    <Box>{train.departure?.timeHour}:{train.departure?.timeMinute} Uhr</Box>
                                    {train.departureTimeChangeHour === "" ? <></> : <Box sx={{color:'red'}}>{train.departureTimeChangeHour}:{train.departureTimeChangeMinute} Uhr</Box>}
                                </Stack> : <Box>{train.departure?.timeHour}:{train.departure?.timeMinute} Uhr</Box>}

                            </TableCell> : <TableCell>
                                {train.arrival?.timeHour}:{train.arrival?.timeMinute} Uhr
                            </TableCell>}

                        </TableRow>
                    )}</>}

                </TableBody>
            </Table>
        </>
    );
}