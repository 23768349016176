/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WeatherEntry } from '../models/WeatherEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WeatherService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param ort
   * @returns WeatherEntry get current Weather
   * @throws ApiError
   */
  public getCurrentWeather(
    ort: string,
  ): CancelablePromise<WeatherEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/current/{ort}',
      path: {
        'ort': ort,
      },
    });
  }

  /**
   * @param ort
   * @param widgetId
   * @returns WeatherEntry save Weather
   * @throws ApiError
   */
  public saveWeather(
    ort: string,
    widgetId: string,
  ): CancelablePromise<WeatherEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/save-weather/{ort}/{widgetId}',
      path: {
        'ort': ort,
        'widgetId': widgetId,
      },
    });
  }

  /**
   * @param widgetId
   * @returns WeatherEntry load Weather
   * @throws ApiError
   */
  public loadWeatherFromDb(
    widgetId: string,
  ): CancelablePromise<WeatherEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/load-weather/{widgetId}',
      path: {
        'widgetId': widgetId,
      },
    });
  }

}
