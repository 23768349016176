
import Dialog from "../../components/dialog";
import DashboardAddForm from "./DashboardAddForm";

export default function DashboardAddDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  return (
    <Dialog title="Dashboard erstellen" open={open} onClose={onClose}>
      <DashboardAddForm onClose={onClose} />
    </Dialog>
  );
}
