import { useNavigate } from "react-router";
import { Form, Formik, FormikHelpers } from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useCallback } from "react";
import * as yup from "yup";
import FormActions from "../../components/dialog/FormActions";
import {useAddStation} from "../../domains/bahn/data";
import {Stations} from "../../api/domains/bahn";
import AddStationFormContent from "./AddStationFormContent";

export type Props = {
  onClose?: () => void;
  stations: Stations
};

const schema = yup
  .object({
    eva: yup.string().required()
  })
  .required();

export type FormValues = yup.InferType<typeof schema>;

const initialValues: FormValues = {
  eva: ""
};
export default function AddStationForm({ onClose, stations }: Props) {
  const { mutateAsync } = useAddStation()
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (
      { eva }: FormValues,
      { setSubmitting, setStatus }: FormikHelpers<FormValues>
    ) => {
      setStatus(undefined);
      try {
        let station = stations.find((item) => item.eva === eva)
        await mutateAsync({
          eva: eva ?? "1", name: station?.label ?? "Gelnhausen",
        });
        navigate(`/settings/`);
        onClose?.();
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [mutateAsync, navigate, onClose, stations]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <>
        <DialogContent>
          <Form id="bahnhof-erstellen" noValidate>
            <AddStationFormContent stations={stations} />
          </Form>
        </DialogContent>
        <DialogActions>
          <FormActions form="bahnhof-erstellen" onClose={onClose} />
        </DialogActions>
      </>
    </Formik>
  );
}
