import { LinearProgress, Paper, Stack, Typography } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent } from "../../../types";
import { useLoadWeather } from "../../data";

export const CurrentWeatherWidget: WidgetComponent = (props) => {
  const weather = useLoadWeather(
    props.widget.parameters.application,
    props.widget.id
  );

  return (
    <Paper>
      <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
        <Title>{props.widget.parameters.application}</Title>
      </Stack>
      <Stack direction={"column"} sx={{ p: 2 }}>
        <SuspenseBoundary fallback={<LinearProgress />}>
          <Stack direction="column">
            <Stack direction="row" alignItems={"center"}>
              {weather ? (
                <img
                  alt=""
                  src={weather.condition_icon}
                  width="64"
                  height="64"
                ></img>
              ) : (
                <></>
              )}
              <Typography sx={{ fontSize: 64 }}>
                {weather?.temp_c.replace(".0", "")}°C
              </Typography>
            </Stack>
            <Typography>{weather?.condition_text}</Typography>
          </Stack>
        </SuspenseBoundary>
      </Stack>
    </Paper>
  );
};
