/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ich } from '../models/Ich';
import type { UpdateToken } from '../models/UpdateToken';
import type { UserAuthTokensPage } from '../models/UserAuthTokensPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BenutzerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns Ich Der aktuell angemeldete Benutzer
   * @throws ApiError
   */
  public ich(): CancelablePromise<Ich> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ich',
    });
  }

  /**
   * @returns UserAuthTokensPage die hinterlegten Tokentypen des Benutzers
   * @throws ApiError
   */
  public listAuthTokens(): CancelablePromise<UserAuthTokensPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user-auth-tokens',
    });
  }

  /**
   * @param type
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public updateUserAuthToken(
    type: string,
    requestBody: UpdateToken,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/user-auth-tokens/{type}',
      path: {
        'type': type,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param type
   * @returns void
   * @throws ApiError
   */
  public deleteUserAuthToken(
    type: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/user-auth-tokens/{type}',
      path: {
        'type': type,
      },
    });
  }

}
