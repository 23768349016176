import { createContext, useContext, useMemo, type ReactNode } from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {App, type Ich, UpdateToken, UserAuthTokensPage} from "./api/app";
import { useGetApi } from "./data";

type BenutzerContext = {
  benutzer: Ich;
};

const Context = createContext<BenutzerContext | undefined>(undefined);

export function BenutzerProvider({ children }: { children: ReactNode }) {
  const benutzer = useIch();
  const context: BenutzerContext = useMemo(() => ({ benutzer }), [benutzer]);
  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function useBenutzer(): BenutzerContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useBenutzer must be used within a BenutzerProvider");
  }
  return context;
}

function useIch(): Ich {
  const getApi = useGetApi<App>(App);

  return useQuery("ich", async () => (await getApi()).benutzer.ich(), {
    staleTime: 5 * 60 * 1000,
  }).data!;
}

export function useUpdateAuthToken(tokentype: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
      async (data: UpdateToken) =>
          (await getApi()).benutzer.updateUserAuthToken(tokentype,data),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["authtokens"]);
        },
      }
  );
}

export function useDeleteAuthToken(tokentype: string) {
  const getApi = useGetApi<App>(App);
  const queryClient = useQueryClient();
  return useMutation(
      async () => (await getApi()).benutzer.deleteUserAuthToken(tokentype),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["authtokens"]);
        },
      }
  );
}
export function useListAuthTokens(): UserAuthTokensPage {
    const getApi = useGetApi<App>(App);

    return useQuery("authtokens", async () => (await getApi()).benutzer.listAuthTokens(), {
        staleTime: 5 * 60 * 1000,
    }).data!;
}

