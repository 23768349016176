/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuoteEntry } from '../models/QuoteEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuoteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param randomId
   * @returns QuoteEntry get random quote
   * @throws ApiError
   */
  public getRandomQuote(
    randomId: number,
  ): CancelablePromise<QuoteEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/random/{randomId}',
      path: {
        'randomId': randomId,
      },
    });
  }

  /**
   * @param widgetId
   * @returns QuoteEntry load Quote
   * @throws ApiError
   */
  public loadQuoteFromDb(
    widgetId: string,
  ): CancelablePromise<QuoteEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/load-quote/{widgetId}',
      path: {
        'widgetId': widgetId,
      },
    });
  }

}
