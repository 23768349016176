import { useFormikContext } from "formik";
import { ReactNode } from "react";
import CloseButton from "../CloseButton";
import SubmitButton from "../SubmitButton";

export type Props = {
  form?: string;
  onClose?: () => void;
  allowPristine?: boolean;
  closeLabel?: ReactNode;
  submitIcon?: ReactNode;
  submitLabel?: ReactNode;
};

export default function FormActions({
  onClose,
  closeLabel,
  allowPristine,
  submitIcon,
  submitLabel,
  ...props
}: Props) {
  const { dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <>
      {onClose ? (
        <CloseButton disabled={isSubmitting} onClick={onClose}>
          {closeLabel ?? "Schließen"}
        </CloseButton>
      ) : undefined}
      <SubmitButton
        icon={submitIcon}
        disabled={(!allowPristine && !dirty) || !isValid}
        loading={isSubmitting}
        {...props}
      >
        {submitLabel ?? "Speichern"}
      </SubmitButton>
    </>
  );
}
