import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "Dies ist ein Pflichtfeld",
  },
  string: {
    min: (props) => `Muss mindestens ${props.min} Zeichen lang sein.`,
    max: (props) => `Darf höchstens ${props.max} Zeichen lang sein.`,
  },
  number: {
    integer: "Bitte geben Sie eine ganze Zahl ein.",
    min: (props) => `Darf nicht kleiner als ${props.min} sein.`,
    max: (props) => `Darf nicht größer als ${props.max} sein.`,
  },
});
