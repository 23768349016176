import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import DashboardAddDialog from "./DashboardAddDialog";

export default function DashboardAddButton() {
  const popupState = usePopupState({
    variant: "dialog",
    popupId: `dashboard-erstellen`,
  });

  return (
    <>
      <Button
          sx={{mt:3}}
        variant="contained"
        startIcon={<Add />}
        {...bindTrigger(popupState)}
      >
        Neues Dashboard
      </Button>
      <DashboardAddDialog {...bindPopover(popupState)} />
    </>
  );
}
