/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemInformationen } from '../models/SystemInformationen';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns SystemInformationen Informationen über das System
   * @throws ApiError
   */
  public getSystemInformationen(): CancelablePromise<SystemInformationen> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/system',
    });
  }

}
