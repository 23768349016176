import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import type { PropsWithChildren } from "react";
import LinkBehavior from "./LinkBehavior";

export type Props = PropsWithChildren<{
  back?: string;
}>;

export default function Title({ back, children }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      {back && (
        <IconButton href={back} edge="start" component={LinkBehavior}>
          <ArrowBack />
        </IconButton>
      )}
      <Typography component="h1" variant="h5">
        {children}
      </Typography>
    </Stack>
  );
}
