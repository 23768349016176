import {LinearProgress, Paper, Stack, Table, TableBody, Typography} from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import {useGetPresences} from "../../data";

export const MyPresenceWidget: WidgetComponent = (props) => (
        <Paper>
            <Stack direction="row" width="100%" sx={{pl:2,pt:2,pr:1}}>
                <Title >Meine Anwesenheit</Title>
            </Stack>
            <SuspenseBoundary fallback={<LinearProgress />}>
                <Content {...props} />
            </SuspenseBoundary>
        </Paper>
);

function Content({ widget }: WidgetComponentProps) {
    const statistik = useGetPresences()
  return (
      <Stack direction="column">
          <Table size="small" sx={{pl:5}}>
              <TableHead>
                  <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Soll</TableCell>
                      <TableCell>Ist</TableCell>
                      <TableCell>Diff</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key="1">
                      <TableCell>dieser Monat</TableCell>
                      <TableCell>{statistik.monthShould}</TableCell>
                      <TableCell>{statistik.monthIs}
                      </TableCell>
                      <TableCell>{statistik.monthDiff}</TableCell>
                  </TableRow>
                  <TableRow key="2">
                      <TableCell>letzte 7 Tage</TableCell>
                      <TableCell>{statistik.last7Should}</TableCell>
                      <TableCell>{statistik.last7Is}
                      </TableCell>
                      <TableCell>{statistik.last7Diff}</TableCell>
                  </TableRow>
                  <TableRow key="3">
                      <TableCell>diese Woche</TableCell>
                      <TableCell>{statistik.weekShould}</TableCell>
                      <TableCell>{statistik.weekIs}
                      </TableCell>
                      <TableCell>{statistik.weekDiff}</TableCell>
                  </TableRow>
                  <TableRow key="4">
                      <TableCell>Heute</TableCell>
                      <TableCell>{statistik.todayShould}</TableCell>
                      <TableCell>{statistik.todayIs}
                      </TableCell>
                      <TableCell>{statistik.todayDiff}</TableCell>
                  </TableRow>

              </TableBody>
          </Table>
          <Stack direction="row" width="100%" sx={{pl:2,pt:2,pr:1,pb:1}}>
              <Typography>Stundensaldo:</Typography>
              <Typography sx={{ marginLeft: "auto" ,pr:10}} >{statistik.saldo}</Typography>
          </Stack>

      </Stack>

  );
}
