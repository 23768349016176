/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComicEntry } from '../models/ComicEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ComicService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ComicEntry get current Comic
   * @throws ApiError
   */
  public getCurrentComic(): CancelablePromise<ComicEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/current',
    });
  }

  /**
   * @param widgetId
   * @returns ComicEntry load current Comic
   * @throws ApiError
   */
  public loadComicFromDb(
    widgetId: string,
  ): CancelablePromise<ComicEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/load-comic/{widgetId}',
      path: {
        'widgetId': widgetId,
      },
    });
  }

}
