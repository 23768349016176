/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProblemsPage } from '../models/ProblemsPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ZabbixService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ProblemsPage All open Problems
   * @throws ApiError
   */
  public listProblems(): CancelablePromise<ProblemsPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/problems',
    });
  }

}
