import {WidgetComponent, WidgetComponentProps} from "../../../types";
import {LinearProgress, Link, Paper, Stack} from "@mui/material";
import Title from "../../../../components/Title";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import {useEffect, useState} from "react";
import GlobalLoading from "../../../../components/GlobalLoading";
import {useLoadComic,xkcd} from "../../data";

export const CurrentComicWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{pl:2,pt:2,pr:1}}>
            <Title>Heutiger Comic</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);
function Content({ widget }: WidgetComponentProps) {
    const comic = useLoadComic(widget.id);
    const [xkcd, setXKCD] = useState<xkcd>({img:""})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);
        setXKCD({img:comic.image});
        setLoading(false);
    }, [comic]);

    return (
        <Stack direction="column">
            {loading ? <GlobalLoading></GlobalLoading> : <Stack direction="row" sx={{pl:3}}>
                <Link href={xkcd.img}><img alt="test" src={xkcd.img} style={{width:"300px",height:"400px",objectFit:"contain"}}></img></Link>
            </Stack>}
        </Stack>
    );
}