import { LinearProgress, Paper, Stack, Table, TableBody } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import { useListProblems } from "../../data";
import { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import GlobalLoading from "../../../../components/GlobalLoading";
import BallotIcon from '@mui/icons-material/Ballot';

export const CurrentProblemsWidget: WidgetComponent = (props) => (
    <Paper>
        <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
            <BallotIcon sx={{ mt: 1 }}></BallotIcon>
            <Title >Open Server Issues</Title>
        </Stack>
        <SuspenseBoundary fallback={<LinearProgress />}>
            <Content {...props} />
        </SuspenseBoundary>
    </Paper>
);

function Content({ widget }: WidgetComponentProps) {
    const problemPage = useListProblems()
    const [loading] = useState(false)

    return (
        <>
            <Table size="small" sx={{ pl: 5 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Host</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <GlobalLoading /> : <>{problemPage?.items?.map((problem) =>
                        <TableRow key={problem.eventid}>
                            <TableCell>{problem.eventid}</TableCell>
                            <TableCell>{problem.name}</TableCell>
                            <TableCell>
                                {problem.hostName}
                            </TableCell>
                        </TableRow>
                    )}</>}

                </TableBody>
            </Table>
        </>
    );
}
