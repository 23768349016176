import React from 'react';
import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import {Box} from '@mui/material';
import FavoriteDeleteButton from '../../components/unitrade/FavoriteDeleteButton';

export function DraggableFavorite(props: any) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
    });
    const style = {
        // Outputs `translate3d(x, y, 0)`
        transform: CSS.Translate.toString(transform),
    };

    return (
        <Box position="relative">
            <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
                {props.children}
            </div>
            <Box sx={{position:"absolute",top:0,right:0,transform:CSS.Translate.toString(transform)}}>
                <FavoriteDeleteButton widgetId={props.widgetId}></FavoriteDeleteButton>
            </Box>
        </Box>

    )
}
