import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { appBarColor } from "../theme";
import MainMenu from "./MainMenu";

export default function NavBar({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) {
  const [showDrawer, setDrawer] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        color={appBarColor}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ gap: 2 }}>
          <IconButton edge="start" onClick={() => setDrawer((s) => !s)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h5">{title ?? "Dashboard"}</Typography>
          {children}
        </Toolbar>
      </AppBar>
      <MainMenu open={showDrawer} />
    </>
  );
}
