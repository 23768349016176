/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FridgeEntry } from '../models/FridgeEntry';
import type { PresenceEntry } from '../models/PresenceEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuftragssystemService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PresenceEntry get presences
   * @throws ApiError
   */
  public getPresences(): CancelablePromise<PresenceEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get-presences',
    });
  }

  /**
   * @param email
   * @param widgetId
   * @returns PresenceEntry save presences
   * @throws ApiError
   */
  public savePresences(
    email: string,
    widgetId: string,
  ): CancelablePromise<PresenceEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/save-presences/{email}/{widgetId}',
      path: {
        'email': email,
        'widgetId': widgetId,
      },
    });
  }

  /**
   * @param widgetId
   * @returns PresenceEntry load presences
   * @throws ApiError
   */
  public loadPresences(
    widgetId: string,
  ): CancelablePromise<PresenceEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/load-presences/{widgetId}',
      path: {
        'widgetId': widgetId,
      },
    });
  }

  /**
   * @param ort
   * @returns FridgeEntry get available meals count
   * @throws ApiError
   */
  public getFridgeCount(
    ort: string,
  ): CancelablePromise<FridgeEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/get-fridge/{ort}',
      path: {
        'ort': ort,
      },
    });
  }

  /**
   * @param ort
   * @returns FridgeEntry get available meals count from db
   * @throws ApiError
   */
  public loadFridgeCount(
    ort: string,
  ): CancelablePromise<FridgeEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/load-fridge/{ort}',
      path: {
        'ort': ort,
      },
    });
  }

}
