/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Deployment } from '../models/Deployment';
import type { DeploymentPage } from '../models/DeploymentPage';
import type { RegisterDeployment } from '../models/RegisterDeployment';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DeploymentsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param seite
   * @param max
   * @returns DeploymentPage All registered deployments
   * @throws ApiError
   */
  public listDeployments(
    seite?: number,
    max: number = 100,
  ): CancelablePromise<DeploymentPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/deployments',
      query: {
        'seite': seite,
        'max': max,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Deployment The registered deployment
   * @throws ApiError
   */
  public registerDeployment(
    requestBody: RegisterDeployment,
  ): CancelablePromise<Deployment> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/deployments',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
