import { Avatar, LinearProgress, Paper, Stack, Tooltip, Typography } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import { useGetFridgeCount } from "../../data";
import { amber, green, red, } from "@mui/material/colors";

export const FridgeWidget: WidgetComponent = (props: WidgetComponentProps) => {
    const fridgeGN = useGetFridgeCount("Gelnhausen")
    const fridgeKL = useGetFridgeCount("Köln")
    return <Paper>
        <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
            <Title>Kühlschrank</Title>
        </Stack>
        <Stack direction={"column"} sx={{ p: 2 }}>
            <SuspenseBoundary fallback={<LinearProgress />}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                        <FridgeIndicator mealCount={fridgeGN.count!} maxMealCapacity={32}></FridgeIndicator>
                        <Typography>Gelnhausen</Typography>
                    </Stack>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                        <FridgeIndicator mealCount={fridgeKL.count!} maxMealCapacity={5}></FridgeIndicator>
                        <Typography>Köln</Typography>
                    </Stack>
                </Stack>
            </SuspenseBoundary>
        </Stack>
    </Paper>
}

export const FridgeStandortWidget: WidgetComponent = (props: WidgetComponentProps) => {
    const fridge = useGetFridgeCount(props.widget.parameters.application)
    return <Paper>
        <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
            <Title>Kühlschrank {props.widget.parameters.application}</Title>
        </Stack>
        <Stack direction={"column"} sx={{ p: 2 }}>
            <SuspenseBoundary fallback={<LinearProgress />}>
                <Stack direction="row" alignItems={"center"} spacing={2}>
                    <FridgeIndicator mealCount={fridge.count!} maxMealCapacity={32}></FridgeIndicator>
                    <Typography>Essen verfügbar</Typography>
                </Stack>
            </SuspenseBoundary>
        </Stack>
    </Paper>
}

const FridgeIndicator = (props: { mealCount: number, maxMealCapacity: number }) => {
    const value = props.mealCount / props.maxMealCapacity * 100;
    const color = value > 50 ? green[500] : value > 25 ? amber[500] : red[700];

    return <Tooltip title={`${props.mealCount} Essen verfügbar`}><Avatar sx={{ bgcolor: color }}>{props.mealCount}</Avatar></Tooltip>
}
