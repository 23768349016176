import Paper from "@mui/material/Paper";
import Main from "../../components/Main";
import SuspenseBoundary from "../../components/SuspenseBoundary";
import {useApplication} from "../../domains/applications/data";
import {useNavigate, useParams} from "react-router";
import Title from "../../components/Title";
import EnvironmentInfo from "../../domains/applications/widgets/current-application-versions/EnvironmentInfo";
import {IconButton, List, Stack} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ApplicationItem() {
    const params = useParams();
    const navigate = useNavigate();
    const application = useApplication(params.application!);
    return(<Main>
        <SuspenseBoundary>
            <Paper sx={{ m: 5 }}>
                <Stack direction="row" sx={{ pt: 3, pl:1 }}>
                    <IconButton>
                        <ArrowBackIcon onClick={() => navigate(-1)}></ArrowBackIcon>
                    </IconButton>
                    <Title>{application.label}</Title>
                </Stack>
                <List dense sx={{ pl:5 }}>
                    {application.environments.map((environment) => (
                        <EnvironmentInfo key={environment.label} environment={environment} />
                    ))}
                </List>
            </Paper>
        </SuspenseBoundary>

    </Main>)
}