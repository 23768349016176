/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateStation } from '../models/CreateStation';
import type { Stations } from '../models/Stations';
import type { TimetableEntry } from '../models/TimetableEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BahnService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param evaId
   * @returns TimetableEntry get timetable
   * @throws ApiError
   */
  public getTimetable(
    evaId: string,
  ): CancelablePromise<TimetableEntry> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/plan/{evaId}',
      path: {
        'evaId': evaId,
      },
    });
  }

  /**
   * @param evaId
   * @returns any no content
   * @throws ApiError
   */
  public deleteStation(
    evaId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/station/{evaId}',
      path: {
        'evaId': evaId,
      },
    });
  }

  /**
   * @returns Stations list available stations
   * @throws ApiError
   */
  public listStations(): CancelablePromise<Stations> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/stations',
    });
  }

  /**
   * @param requestBody
   * @returns any add Station
   * @throws ApiError
   */
  public addStation(
    requestBody: CreateStation,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/stations',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Stations list all available stations
   * @throws ApiError
   */
  public listAllStations(): CancelablePromise<Stations> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/stations/all',
    });
  }

}
