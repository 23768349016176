import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Application,
  ApplicationPage,
  ApplicationsDomain, DeploymentPage,
  OpenAPI,
  RegisterDeployment,
} from "../../api/domains/applications";
import { useGetApi } from "../../data";

function useGetApplicationsAPI() {
  return useGetApi<ApplicationsDomain>(ApplicationsDomain, OpenAPI);
}

export function useApplication(applicationLabel: string): Application {
  const getAPI = useGetApplicationsAPI();

  const application = useQuery(
    ["domains", "applications", "application", applicationLabel],
    async () => (await getAPI()).applications.getApplication(applicationLabel)
  ).data;

  if (!application) throw new Error("Application not found");
  return application;
}
export function useListApplication(
  seite: number,
  max: number
): ApplicationPage {
  const getAPI = useGetApplicationsAPI();

  return useQuery(["applications", { seite, max }], async () =>
    (await getAPI()).applications.listApplications(seite, max)
  ).data!;
}

export function useRegisterDeployment() {
  const getApi = useGetApplicationsAPI();
  const queryClient = useQueryClient();
  return useMutation(
    async (data: RegisterDeployment) =>
      (await getApi()).deployments.registerDeployment(data),
    {
      onSuccess: (deployment) => {
        queryClient.invalidateQueries(["applications"]);
      },
    }
  );
}
export function useDeleteApplication(
  applicationLabel: string,
  applicationId: string
) {
  const getApi = useGetApplicationsAPI();
  const queryClient = useQueryClient();
  return useMutation(
    async () =>
      (await getApi()).applications.deleteApplication(
        applicationLabel,
        applicationId
      ),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([
          "domains",
          "applications",
          "application",
        ]);
      },
    }
  );
}
export function useListDeployments(
    seite: number,
    max: number
): DeploymentPage {
  const getAPI = useGetApplicationsAPI();

  return useQuery(["deployments", { seite, max }], async () =>
      (await getAPI()).deployments.listDeployments(seite, max)
  ).data!;
}

