import { LinearProgress, Paper, Stack, Typography } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent } from "../../../types";
import {useFavorite} from "../../data";

export const BelegTileWidget: WidgetComponent = (props) => {
    const tile = useFavorite(props.widget.id);
    const formatBigDecimal = (value: number): string => {
        return Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    return (
        <Paper>
            <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
                <Title>{tile.header}</Title>
            </Stack>
            <Stack direction={"column"} sx={{ p: 2 }}>
                <SuspenseBoundary fallback={<LinearProgress />}>
                    <Stack direction="column">
                        <Typography variant="h1">{tile.anzahl}</Typography>
                        <Stack direction="column" sx={{ alignItems: 'flex-end' }}>
                            <Typography style={{ fontWeight: 'bold' }}>{formatBigDecimal(tile.betragSumme ?? 0)} €</Typography>
                            <Typography>{formatBigDecimal(tile.betragNetto ?? 0)} €</Typography>
                        </Stack>
                    </Stack>
                </SuspenseBoundary>
            </Stack>
        </Paper>
    );
};