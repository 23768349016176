import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useId } from "react";
import { useDeleteDashboard } from "./data";

export default function DashboardDeleteButton({
  dashboardId,
}: {
  dashboardId: string;
}) {
  const { mutate } = useDeleteDashboard(dashboardId);
  const popupState = usePopupState({ variant: "popover", popupId: useId() });

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <DeleteIcon />
      </IconButton>
      <Dialog {...bindPopover(popupState)}>
        <DialogTitle id="alert-dialog-title">
          Dashboard wirklich löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sie sind dabei dieses Dashboard zu löschen. Sind Sie sich sicher?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={popupState.close} color="inherit">
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              mutate();
              popupState.close();
            }}
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
