import { Field, useFormikContext } from "formik";
import {Alert, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Stack} from "@mui/material";
import { TextField } from "formik-mui";
import * as React from "react";
import { FormValues } from "./UserAccountForm";
import AuthTokenDeleteButton from "./AuthTokenDeleteButton";
import {useListAuthTokens} from "../../benutzer";

export default function UserAccountFormContent() {
  const { status } = useFormikContext<FormValues>();
  const userAuthTokensPage = useListAuthTokens();
  return (
    <Stack spacing={2} sx={{ pt: 1 }}>
      <Field component={TextField} name="auftragssystemToken" label="Auftragssystemtoken" required />
        {userAuthTokensPage.items.length === 0 ? (<></>): (
            <Paper>
                <List>
                    {userAuthTokensPage.items.map((tokenType) => (
                        <ListItem>
                            <ListItemText primary={tokenType[0].toUpperCase() + tokenType.slice(1) + "token"} />
                            <ListItemSecondaryAction>
                                <AuthTokenDeleteButton tokenType={tokenType}></AuthTokenDeleteButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        )}
      <Field component={TextField} name="tech" label="Benutzerkürzel" />


      {status && <Alert severity="error">{status}</Alert>}
    </Stack>
  );
}
