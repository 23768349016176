import { withProfiler } from "@sentry/react";
import { setDefaultOptions } from "date-fns";
import { de } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "./auth";
import { BenutzerProvider } from "./benutzer";
import GlobalLoading from "./components/GlobalLoading";
import SuspenseBoundary from "./components/SuspenseBoundary";
import "./i18n";
import Pages from "./pages";
import { SystemInformationenProvider } from "./system-informationen";
import { ThemeProvider } from "./theme";

setDefaultOptions({ locale: de });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false,
    },
  },
});

function App() {
    return (
      <ThemeProvider>
        <SuspenseBoundary fallback={<GlobalLoading />}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider maxSnack={3}>
              <SystemInformationenProvider>
                <AuthProvider>
                  <BenutzerProvider>
                    <Pages />
                  </BenutzerProvider>
                </AuthProvider>
              </SystemInformationenProvider>
            </SnackbarProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </SuspenseBoundary>
      </ThemeProvider>
    );
}

export default withProfiler(App);
