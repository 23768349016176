import {useGetApi} from "../../data";
import {ComicDomain, ComicEntry} from "../../api/domains/comic";
import {OpenAPI} from "../../api/domains/comic";
import {useQuery} from "react-query";

function useGetComicAPI() {
    return useGetApi<ComicDomain>(ComicDomain, OpenAPI);
}
export function useLoadComic(widgetId:string): ComicEntry {
    const getAPI = useGetComicAPI();

    const comic = useQuery(
        ["comic"],
        async () => (await getAPI()).comic.loadComicFromDb(widgetId)
    ).data;

    if (!comic) throw new Error("Comic not found");
    return comic;
}
export interface xkcd{
    img: string,
}
