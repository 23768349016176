import {Box, Paper, Stack, Typography} from "@mui/material";
import Layout from "../../components/Layout";
import Main from "../../components/Main";
import AddStationButton from "../../components/bahn/AddStationButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useListStations} from "../../domains/bahn/data";
import StationDeleteButton from "../../components/bahn/DeleteStationButton";

export default function Settings() {
  const stations = useListStations() ?? []
  return (
    <Layout title="Einstellungen">
      <Main>
        <Paper>
          <Stack direction="row" justifyContent="space-between">
            <Typography sx={{ml:2, pt:2}} variant="h6">Bahnhöfe</Typography>
            <Box sx={{mt:1, pr:2}}>
              <AddStationButton></AddStationButton>
            </Box>

          </Stack>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Eva</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Aktion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stations.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.eva}</TableCell>
                    <TableCell>{row.label}</TableCell>
                    <TableCell><StationDeleteButton evaId={row.eva}
                    ></StationDeleteButton></TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>

        </Paper>
      </Main>
    </Layout>
  );
}
