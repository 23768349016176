
import Dialog from "../../components/dialog";
import AddStationForm from "./AddStationForm";
import {useListAllStations} from "../../domains/bahn/data";

export default function AddStationDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  const AllStations = useListAllStations() ?? [];
  return (
    <Dialog title="Bahnhof hinzufügen" open={open} onClose={onClose}>
      <AddStationForm  onClose={onClose} stations={AllStations} />
    </Dialog>
  );
}
