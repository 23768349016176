
import Dialog from "../../components/dialog";
import TechAddForm from "./TechAddForm";

export default function DashboardAddDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  return (
    <Dialog title="Benutzer hinterlegen" open={open} onClose={onClose}>
      <TechAddForm onClose={onClose} />
    </Dialog>
  );
}
