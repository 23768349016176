import { LinearProgress } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { ReactNode, Suspense } from "react";
import FallbackAlert from "./FallbackAlert";

export default function SuspenseBoundary({
  fallback,
  children,
}: {
  fallback?: ReactNode;
  children: ReactNode;
}) {
  return (
    <Suspense fallback={fallback ?? <LinearProgress />}>
      <ErrorBoundary fallback={FallbackAlert}>{children}</ErrorBoundary>
    </Suspense>
  );
}
