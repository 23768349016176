import { Paper, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import Layout from "../../components/Layout";
import Main from "../../components/Main";
import { useListApplication } from "../../domains/applications/data";
import ApplicationDeleteButton from "./ApplicationDeleteButton";

export default function ApplicationList() {
  const [seite, setSeite] = useState(0);
  const [max, setMax] = useState(10);
  const application = useListApplication(seite, max);

  return (
    <Layout title="Anwendungen">
      <Main>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Aktion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {application.items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell>
                    <ApplicationDeleteButton
                      applicationLabel={row.label}
                      applicationId={row.id}
                    ></ApplicationDeleteButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TablePagination
              page={seite}
              rowsPerPage={max}
              count={application.anzahlElemente}
              onPageChange={(_, p) => setSeite(p)}
              onRowsPerPageChange={(e) => setMax(parseInt(e.target.value, 10))}
              sx={{ borderBottom: 0 }}
            />
          </Table>
        </Paper>
      </Main>
    </Layout>
  );
}
