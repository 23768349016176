import { Alert, Card, LinearProgress, Link, List, Stack } from "@mui/material";
import SuspenseBoundary from "../../../../components/SuspenseBoundary";
import Title from "../../../../components/Title";
import type { WidgetComponent, WidgetComponentProps } from "../../../types";
import { useApplication } from "../../data";
import EnvironmentInfo from "./EnvironmentInfo";

export const CurrentApplicationVersionsWidget: WidgetComponent = (props) => (
  <Card>
    <Stack direction="row" width="100%" sx={{ pl: 2, pt: 2, pr: 1 }}>
      <Title>
        <Link
          color="inherit"
          href={`/application/${props.widget.parameters.application}`}
        >
          {props.widget.parameters.application}
        </Link>
      </Title>
    </Stack>
    <SuspenseBoundary fallback={<LinearProgress />}>
      <Content {...props} />
    </SuspenseBoundary>
  </Card>
);

function Content({ widget }: WidgetComponentProps) {
  const { environments } = useApplication(widget.parameters.application);

  if (!environments.length) {
    return <Alert severity="info">No environments.</Alert>;
  }

  return (
    <List dense>
      {environments.map((environment) => (
        <EnvironmentInfo key={environment.label} environment={environment} />
      ))}
    </List>
  );
}
