
import {useGetApi} from "../../data";
import { useQuery} from "react-query";
import {OpenAPI, WeatherDomain, WeatherEntry} from "../../api/domains/weather";

function useGetWeatherAPI() {
    return useGetApi<WeatherDomain>(WeatherDomain, OpenAPI);
}

export function useSaveWeather(ort: string,widgetId: string): WeatherEntry {
    const getAPI = useGetWeatherAPI();
    const weather = useQuery(
        ["weather_save",ort,widgetId],
        async () => (await getAPI()).weather.saveWeather(ort,widgetId)
    ).data;

    if (!weather) throw new Error("Weather not found");
    return weather;
}
export function useLoadWeather(ort: string,widgetId: string): WeatherEntry {
    const getAPI = useGetWeatherAPI();
    const weather = useQuery(
        ["weather",ort,widgetId],
        async () => (await getAPI()).weather.loadWeatherFromDb(widgetId)
    ).data;

    if (!weather) throw new Error("Weather not found");
    return weather;
}
