import {useGetApi} from "../../data";
import {GetTech, OpenAPI, SaveTech, UnitradeDomain, WidgetRequest, Widgets} from "../../api/domains/unitrade";
import {useMutation, useQuery, useQueryClient} from "react-query";

function useGetUnitradeAPI() {
    return useGetApi<UnitradeDomain>(UnitradeDomain, OpenAPI);
}
export function useTech(): GetTech {
    const getAPI = useGetUnitradeAPI();
    const techUser = useQuery(
        ["tech"],
        async () => (await getAPI()).unitrade.getTech()
    ).data;
    if (!techUser) throw new Error("Tech not found");
    return techUser;
}
export function useAddTech() {
    const getApi = useGetUnitradeAPI();
    const queryClient = useQueryClient();
    return useMutation(
        async (data: SaveTech) =>
            (await getApi()).unitrade.saveTech(data),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["tech"]);
            },
        }
    );
}
export function useFavoriten(tech: string): Widgets {
    const getAPI = useGetUnitradeAPI();
    const favorites = useQuery(
        ["favoriten"],
        async () => (await getAPI()).unitrade.getFavoritenWidgets(tech)
    ).data;
    if (!favorites) throw new Error("Favorites not found");
    return favorites;
}
export function useDeleteFavorite(id: string) {
    const getApi = useGetUnitradeAPI();
    const queryClient = useQueryClient();
    return useMutation(
        async () =>
            (await getApi()).unitrade.deleteFavorite(id),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["favoriten"]);
            },
        }
    );
}
export function useUpdateFavoriteOrder() {
    const getApi = useGetUnitradeAPI();
    const queryClient = useQueryClient();
    return useMutation(
        async (data: WidgetRequest) =>
            (await getApi()).unitrade.updateFavoritenOrder(data),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["favoriten"]);
            },
        }
    );
}